<template>
  <div class="section-content section-pickup pt-4 w-100">
    <b-container class="container-xlg pl-xxl-0 pr-xxl-0">
      <esports-title
        title="Pickup"
        sub_title="注目の大会"
        class="pb-2 pb-xxl-4"
      />
      <esports-pickup-loading v-if="isLoading" :events="6" />
      <b-row v-else cols="2" cols-md="3" align-v="stretch">
        <b-col v-for="(event, index) in mostedEvents" :key="index">
          <b-card class="mb-3 mb-md-2 mb-lg-4 border-0 rounded-0" no-body>
            <div
              @click="redirectEventDetail($router, event.eventId)"
              role="button"
            >
              <b-card-img-lazy
                :src="event.picture"
                :alt="event.title"
                class="rounded-0"
                v-bind="mainProps()"
                show
              ></b-card-img-lazy>
            </div>
            <div class="article-content">
              <span class="top-title" v-if="event.onlineFlag">オンライン</span>
              <span class="top-title box-black" v-else>{{
                event.venue || "オフライン"
              }}</span>
              <h4
                @click="redirectEventDetail($router, event.eventId)"
                role="button"
              >
                {{ event.title }}
              </h4>
            </div>
            <b-card-header v-if="event.startDate">
              <span class="date">{{ event.startDate }}</span>
            </b-card-header>
          </b-card>
        </b-col>
      </b-row>
      <p class="text-center py-2 pb-xxl-5">
        <b-button
          href="/events/pickup"
          :title="$t('general_inputs.btn_view_all')"
          class="btn-view-all mt-xxl-1"
          @mouseover="handleOverBtn"
          @mouseleave="handleLeaveBtn"
          :class="{'animate__animated animate__pulse': animated}"
          ><span class="pt-1 d-block">{{
            $t("general_inputs.btn_view_all")
          }}</span></b-button
        >
      </p>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "SectionPickup",
  data() {
    return {
      mostedEvents: [],
      limit: true,
      isLoading: false,
    };
  },
  async mounted() {
    await this.getEventMostVisited();
  },
  methods: {
    async getEventMostVisited() {
      this.isLoading = true;
      const result = await this.$store
        .dispatch("home/getEventMostVisited", { limit: this.limit })
        .catch((err) => {
          console.log(err);
        });

      if (result) {
        this.mostedEvents = result.map((event, index) => {
          event.picture = `${process.env.VUE_APP_BACKEND + event.picture}`;

          if (event.startDate) {
            event.startDate = moment(event.startDate).format("YYYY-MM-DD");
          }
          return event;
        });

        this.isLoading = false;
      }
    },
  },
};
</script>