<template>
  <div class="w-100">
    <SectionBanner />
    <SectionPickup />
    <SectionArchive />
    <SectionSlider />
    <SectionPhone />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SectionPhone from "@/views/Landing/SectionPhone";
import SectionBanner from "@/views/Landing/SectionBanner";
import SectionSlider from "@/views/Landing/SectionSlider";
import SectionPickup from "@/views/Landing/SectionPickup";
import SectionArchive from "@/views/Landing/SectionArchive";

export default {
  name: "HomePage",
  title: "home",
  components: {
    SectionPhone,
    SectionBanner,
    SectionSlider,
    SectionPickup,
    SectionArchive
  },
  computed: {
    ...mapGetters("auth", ["authenticated"]),
  },
  async mounted() {
    this.authenticated && await this.addFirstAccess();
  },
  methods: {
    async addFirstAccess() {
      await this.$store
        .dispatch("home/addFirstAccess")
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>