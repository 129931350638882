<template>
  <div class="section-banner w-100 mb-2 mb-xxl-5">
    <b-container class="pl-xxl-0 pr-xxl-0">
      <esports-banner-loading v-if="isLoading" />
      <b-row v-else no-gutters align-v="stretch">
        <b-col :lg="!sideEvents.length ? 12 : 9">
          <b-carousel
            id="carousel-fade"
            fade
            v-model="slide"
            controls
            img-width="1050"
            img-height="520"
            v-if="centerEvents && centerEvents.length"
          >
            <b-carousel-slide
              v-for="(event, index) in centerEvents"
              :key="index"
            >
              <template #img>
                <span
                  role="button"
                  @click="redirectEventDetail($router, event.eventId)"
                >
                  <b-img-lazy
                    v-bind="mainProps()"
                    :src="event.picture"
                    :alt="event.title"
                    class="d-block w-100"
                    show
                  ></b-img-lazy>
                </span>
              </template>
            </b-carousel-slide>
          </b-carousel>
          <div ref="outer-thumbs" class="outer-thumbs">
            <div ref="thumbs-slide" class="thumbs d-flex">
              <template v-for="(thumb, index) in centerEvents">
                <div
                  class="thumb"
                  :key="index"
                  @click="setSlide(index)"
                  :class="{ active: slide === index }"
                  :style="{
                    'background-image': 'url(' + thumb.picture + ')',
                    'width': thumbW + 'px'
                  }"
                ></div>
              </template>
            </div>
          </div>
        </b-col>
        <b-col lg="3" v-if="sideEvents.length">
          <div class="events h-100 d-flex flex-column">
            <div
              class="box-event d-flex"
              role="button"
              @click="redirectEventDetail($router, event.eventId)"
              v-for="(event, index) in sideEvents"
              :key="index"
            >
              <div
                class="img"
                :style="{
                  'background-image': 'url(' + event.picture + ')',
                }"
              ></div>
              <div class="title">
                <h2>{{ event.title }}</h2>
                <h6 v-if="event.startDate">{{ event.startDate }}</h6>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      slide: 0,
      centerEvents: [],
      sideEvents: [],
      isLoading: false,
      thumbW: 0
    };
  },
  watch: {
    slide(nVal) {
      let slideW = 0;
      let slides = document.querySelectorAll(".thumbs .thumb");
      const thumbsSlide = this.$refs["thumbs-slide"];
      const outerThumbs = this.$refs["outer-thumbs"];
      const thumbW = Math.round(outerThumbs.clientWidth / 3);
      slideW = thumbW;

      if (nVal >= 3) {
        //slide > 3
        let remainSlide = nVal - 3;
        let offsetSlide = (remainSlide*2) + 6;

        if(window.innerWidth < 768) offsetSlide = 0;
        
        slideW = remainSlide * slideW + thumbW + offsetSlide;
      }

      if (nVal === 0) {
        // slide 0
        slideW = 0;
      }

      if (this.centerEvents.length === 3) {
        //slides = 3
        slideW = 0;
      }

      thumbsSlide.style.transform = "translateX(-" + slideW + "px)";
    },
  },
  async mounted() {
    await this.getEventTop();

    this.setWidthThumbs();

    window.addEventListener(
      "resize",
      () => {
        this.setWidthThumbs();
      },
      true
    );
  },
  methods: {
    setSlide(slide) {
      this.slide = slide;
    },
    setWidthThumbs() {
      this.$nextTick(() => {
        let widths = 0;
        const outerThumbs = this.$refs["outer-thumbs"];
        let thumbW =
          outerThumbs?.clientWidth && Math.round(outerThumbs.clientWidth / 3);

        this.thumbW = thumbW;

        //if (window.innerWidth > 1200) thumbW -= 2;

        const thumbsSlide = this.$refs["thumbs-slide"];

        if (thumbsSlide) {
          const thumbs = thumbsSlide?.querySelectorAll(".thumb");
          thumbs.forEach((thumb, index) => {
            widths += thumbW;
          });

          if (window.innerWidth > 1200) widths += 15;

          if (this.centerEvents.length <= 3) return;

          if (this.centerEvents.length > 3) widths -= 2;

          this.$refs["thumbs-slide"].style.width = widths + "px";
        }
      });
    },
    async getEventTop() {
      this.isLoading = true;
      const result = await this.$store
        .dispatch("home/getEventTop")
        .catch((err) => {
          console.log(err);
          alert("please reload page");
        });

      if (result) {
        this.centerEvents = result.centerEvents ? result.centerEvents.map((event, index) => {
          event.picture = `${process.env.VUE_APP_BACKEND + event.picture}`;
          return event;
        }) : [];

        if (result.sideEvents && result.sideEvents.length > 3) {
          this.sideEvents = result.sideEvents.slice(0, 3);
          this.sideEvents = this.filterEvents(this.sideEvents);
          this.isLoading = false;
          return;
        }

        this.sideEvents = result.sideEvents ? this.filterEvents(result.sideEvents) : [];
        this.isLoading = false;
      }
    },
    filterEvents(events) {
      return events.map((event, index) => {
        event.picture = `${process.env.VUE_APP_BACKEND + event.picture}`;
        if (event.startDate) {
          event.startDate = moment(event.startDate).format("YYYY.MM.DD");
        }
        return event;
      });
    },
  },
};
</script>